import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import Gallery from "../../components/Gallery";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import Select from "react-select";
import { triggerChooseimg } from "../../components/ChooseImg";

const JobView = () => {
  useEffect(() => {
    // fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <div className="row">
            <div className="col-lg-3">
              <div className="bg-white cards   p-4 rounded shadow gap-4 tabs-box mb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>All Filters</h5>
                  <button class="badge bg-primary fw-light ms-2">Clear</button>
                </div>

                <div class="accordion mt-4" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Candidate Status
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed"
                          />
                          <label className="form-check-label" for="Interviewed">
                            Interviewed
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed0"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed0"
                          >
                            Hired
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed1"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed1"
                          >
                            Reject
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="Interviewed2"
                          />
                          <label
                            className="form-check-label"
                            for="Interviewed2"
                          >
                            have Not Talked
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Candidate Relevancy
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="high"
                          />
                          <label className="form-check-label" for="high">
                            High Match
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="low"
                          />
                          <label className="form-check-label" for="low">
                            Low Match
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="bg-white cards d-flex p-4 rounded shadow gap-4 tabs-box mb-2 overflow-auto">
                <a className=" mytabs active">All Candidates (190) </a>
                <a className="mytabs">Applied Candidates (100) </a>
                <a className="mytabs  ">Contact Candidates (90) </a>
              </div>

              <div className="col ">
                <div className="bg-white cards   p-4 rounded shadow gap-4  mb-2  ">
                  <div className="media d-flex align-items-center mb-3">
                    <img
                      className=" rounded"
                      alt="Image placeholder"
                      src="https://demo.themesberg.com/volt/assets/img/team/profile-picture-3.jpg"
                      width={60}
                    />
                    <div className="media-body ms-2 text-dark align-items-center  ">
                      <span className="mb-0 font-medium fw-bold text-gray-900">
                        Manish Kumar
                      </span>
                      <div className="mb-0 font-small  ">
                        <span className="fw-bold text-gray-500">
                          {" "}
                          Location :{" "}
                          <b className="text-gray-900">
                            Razapur kurd, Delhi, 5.9KM{" "}
                          </b>
                        </span>

                        <span
                          className="fw-bold text-gray-500 ms-2 border-left ps-2"
                          style={{ borderLeft: "2px solid grey" }}
                        >
                          {" "}
                          Current Sallary:{" "}
                          <b className="text-gray-900"> ₹2500/month </b>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="text-gray-500 text-nowrap">Key Info:</div>
                    <div className="text-gray-500  ">
                      <span class="badge bg-primary fw-light ms-2">
                        <i className="ri-graduation-cap-fill"></i> Graduate
                      </span>

                      <span class="badge bg-primary fw-light ms-2">
                        <i className="ri-graduation-cap-fill"></i> Speaks Good
                        English
                      </span>

                      <span class="badge bg-primary fw-light ms-2">
                        <i className="ri-shopping-bag-fill"></i> 8 Years
                      </span>

                      <span class="badge bg-primary fw-light ms-2">
                        <i className="ri-men-line"></i> Male ,25Y
                      </span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="text-gray-500 text-nowrap">
                      Previous Job:
                    </div>
                    <div className="text-gray-500  ms-2">
                      <span className="text-gray-900">
                        {" "}
                        Web Designer At Ontech Digital Technologies Pvt. Ltd.
                      </span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="text-gray-500 text-nowrap">
                      Industries:{" "}
                    </div>
                    <div className="text-gray-500  ms-2">
                      <span className="text-gray-900">
                        {" "}
                        Software & IT Services
                      </span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="text-gray-500 text-nowrap">Language: </div>
                    <div className="text-gray-500  ms-2">
                      <span className="text-gray-900"> Hind, English</span>
                    </div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="text-gray-500 text-nowrap">Skill:</div>
                    <div className="text-gray-500  ">
                      <span class="badge bg-primary fw-light ms-2">SEO</span>

                      <span class="badge bg-primary fw-light ms-2">
                        Boostrap
                      </span>

                      <span class="badge bg-primary fw-light ms-2">HTML</span>
                      <span class="badge bg-primary fw-light ms-2">CSS</span>
                    </div>
                  </div>
                  <hr />
                  <div className="row align-items-center">
                    <div className="col-md-3 mb-3">
                      <select
                        className="form-select mb-0 bg-secondary text-dark mb-3"
                        id="gender"
                        aria-label="Gender select example"
                      >
                        <option selected="selected">Select Status</option>
                        <option value={1}>Active</option>
                        <option value={2}>Unactive</option>
                      </select>
                    </div>

                    <div className="col-md-9 mb-3 text-end">
                      <button
                        class="btn btn-outline-primary mb-3"
                        type="button"
                      >
                        <i className="ri-file-reduce-fill"></i> View Profile &
                        Resume
                      </button>

                      <button
                        type="button"
                        className="btn btn-primary d-inline-flex align-items-center ms-2 mb-3"
                      >
                        <i className="ri-send-plane-2-fill me-1"></i> Send SMS
                      </button>

                      <button
                        type="button"
                        className="btn btn-success text-white d-inline-flex align-items-center ms-2 mb-3"
                      >
                        <i className="ri-whatsapp-line me-1"></i> Whatsapp
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default JobView;

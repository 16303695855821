import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddJob = () => {
  const [loading, setLoading] = useState(true); // Add loading state

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [showCode, setShowCode] = useState(false);
  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const toggleShowCode = () => {
    setShowCode(!showCode);
  };

  const submitData = async () => {
    setSubmitLoading(false);

    console.log(formData);
    try {
      await axiosInstance.post("/admin/add-page", formData);
      console.log("Promo Code Add successfully!");
      toast.success("Promo Add successfully!");
      navigate("/all-page");
    } catch (error) {
      console.error("Error On Promo code:", error);
      console.log(formData);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }
  };

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Page
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">Add Page</h1>
            </div>
            <div>
              {" "}
              <Link
                to="/all-page"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>
        <form method="post" id="add-blog-form">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="card border-0 shadow components-section mb-4">
                <div className="card-body ">
                  <div className="row mb-4">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Job Type <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <label
                                id="jobtype1"
                                className={`btn 
                                ${
                                  formData.jobtype === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Full Time
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="jobtype"
                                  id="jobtype1"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                id="jobtype2"
                                className={`btn 
                                ${
                                  formData.jobtype === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Part Time
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="jobtype"
                                  id="jobtype2"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Job Title <span className="text-danger">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="jobTitle"
                              value={formData.jobTitle}
                              name="jobTitle"
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Job Role / Area of work{" "}
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select className="form-control" name="Gender">
                              <option disabled=""> Select Job Role</option>
                              <option value={1}>Orthopedics</option>
                              <option value={2}>Cardiology</option>
                              <option value={0}>Surgery</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobLocation">
                              Job Location
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              name="jobLocation"
                              id="jobLocation"
                            >
                              <option disabled=""> Select Location</option>
                              <option value={1}>Delhi</option>
                              <option value={2}>Punjab</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobOpen"> No Of Openings</label>
                            <input
                              type="number"
                              className="form-control"
                              id="jobOpen"
                              value={formData.jobOpen}
                              name="jobOpen"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-0 shadow components-section mb-4">
                <div className="card-body ">
                  <h6> Candidate Requirement</h6>

                  <div className="row mb-4">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Total Experience Of Candidate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <label
                                id="totalExprience1"
                                className={`btn 
                                ${
                                  formData.totalExprience === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Any
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience1"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                id="totalExprience2"
                                className={`btn 
                                ${
                                  formData.totalExprience === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Fresher Only
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience2"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                id="totalExprience3"
                                className={`btn 
                                ${
                                  formData.totalExprience === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Experience Only
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="totalExprience"
                                  id="totalExprience3"
                                  value="3"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="jobTitle">
                              Minimum Experience
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              name="miniExperience"
                            >
                              <option disabled=""> Select Experience</option>
                              <option value={1}>1 years</option>
                              <option value={2}>2 Years</option>
                              <option value={3}>3 Years</option>
                              <option value={4}>4 Years</option>
                              <option value={5}>5 Years</option>
                              <option value={6}>6 Years</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-4">
                            <label htmlFor="maxExperience">
                              Maxmium Experience
                              <span className="text-danger">*</span>{" "}
                            </label>

                            <select
                              className="form-control"
                              name="maxExperience"
                            >
                              <option disabled=""> Select Experience</option>
                              <option value={1}>1 years</option>
                              <option value={2}>2 Years</option>
                              <option value={3}>3 Years</option>
                              <option value={4}>4 Years</option>
                              <option value={5}>5 Years</option>
                              <option value={6}>6 Years</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Minimum Qualification
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                id="minQualification"
                                className={`btn 
                                ${
                                  formData.minQualification === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                {"<10 th Pass"}
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="minQualification"
                                  id="minQualification"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                id="minQualification2"
                                className={`btn ms-2 
                                ${
                                  formData.minQualification === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                10th pass or above
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="minQualification"
                                  id="minQualification2"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.minQualification === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                12th pass or above
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="minQualification"
                                  value="3"
                                  onChange={handleChange}
                                />
                              </label>
                              <label
                                className={`btn ms-2 
                                ${
                                  formData.minQualification === "4"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Graduate/ Post Graduate
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="minQualification"
                                  value="4"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              Gender Preference
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.Gender === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Both gender allowed
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="Gender"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.Gender === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Male
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="Gender"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>
                              <label
                                className={`btn ms-2 
                                ${
                                  formData.Gender === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Female
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="Gender"
                                  value="3"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              English Required
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.englishReq === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Does not speak english
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="englishReq"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Speaks Thoda English
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="englishReq"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "3"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Speaks Good English
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="englishReq"
                                  value="3"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.englishReq === "4"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Speaks Fluent English
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="englishReq"
                                  value="4"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="job">
                              i Want call from candidate within
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.callCandidate === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Nearby areas (up to 10 km)
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="callCandidate"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.callCandidate === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Anywhere in Delhi
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="callCandidate"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-4">
                            <label htmlFor="reLocate">
                              Would you like to receive candidates applications
                              from nearby cities of delhi if they are willing to
                              relocate?
                              <span className="text-danger ms-2">*</span>
                            </label>
                            <div className="col-12">
                              <label
                                className={`btn 
                                ${
                                  formData.reLocate === "1"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                Yes
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="reLocate"
                                  value="1"
                                  onChange={handleChange}
                                />
                              </label>

                              <label
                                className={`btn ms-2 
                                ${
                                  formData.reLocate === "2"
                                    ? " btn-primary"
                                    : "btn-pill btn-outline-primary"
                                }`}
                                type="button"
                              >
                                No
                                <input
                                  className="form-check-input d-none  "
                                  type="radio"
                                  name="reLocate"
                                  value="2"
                                  onChange={handleChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  <div className="row">
                    {/* 

                    <div className="col-4 ">
                      <div className="mb-4">
                        <label className="my-1 me-2" htmlFor="status">
                          Status
                        </label>
                      </div>
                    </div>
                    <div className="col-8 ">
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="status"
                            id="status"
                            defaultChecked="checked"
                            defaultValue={1} onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-12">
                    {SubmitLoading ? (
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={submitData}
                      >
                        Add Page
                      </button>
                    ) : (
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        disabled
                      >
                        <span class="ms-1">Loading...</span>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddJob;

// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://backend-vab7.onrender.com/",
});

export default axiosInstance;

export const weburl = "https://backend-vab7.onrender.com/";
export const shopurl = "https://admin.hospitaltraders.com/";
export const mainurl = "https://admin.hospitaltraders.com/";
export const keyLogin = process.env.REACT_APP_LOGIN_KEY;

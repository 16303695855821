import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Login from "./Admin/pages/Login";
// import { BlogProvider } from './fetchdata/BlogContext';

import Dashboard from "./Admin/pages/Dashboard";
import Addblog from "./Admin/pages/Blogs/Addblog";
import AddGallery from "./Admin/pages/Gallery/addGallery";
import BuildBlogs from "./Admin/pages/builder/buildBlogs";
import AdminAllBlogs from "./Admin/pages/Blogs/AllBlogs";
import EditAdminBlogs from "./Admin/pages/Blogs/EditAdminBlogs";
import AllCategory from "./Admin/pages/category/AllCategory";
import EditCategory from "./Admin/pages/category/EditCategory";
import AddCategory from "./Admin/pages/category/AddCategory";
import AllProduct from "./Admin/pages/product/AllProduct";
import EditProduct from "./Admin/pages/product/EditProduct";
import AddProduct from "./Admin/pages/product/AddProduct";

import AddAttribute from "./Admin/pages/attribute/AddAttribute";
import EditAttribute from "./Admin/pages/attribute/EditAttribute";
import AllAttribute from "./Admin/pages/attribute/AllAttribute";

import AddSpecialization from "./Admin/pages/specialization/AddSpecialization";
import EditSpecialization from "./Admin/pages/specialization/EditSpecialization";
import AllSpecialization from "./Admin/pages/specialization/AllSpecialization";

import AddDepartment from "./Admin/pages/department/AddDepartment";
import EditDepartment from "./Admin/pages/department/EditDepartment";
import AllDepartment from "./Admin/pages/department/AllDepartment";

import AddInterested from "./Admin/pages/interested/AddInterested";
import EditInterested from "./Admin/pages/interested/EditInterested";
import AllInterested from "./Admin/pages/interested/AllInterested";

import AddBusiness from "./Admin/pages/Business/AddBusiness";
import EditBusiness from "./Admin/pages/Business/EditBusiness";
import AllBusiness from "./Admin/pages/Business/AllBusiness";

import AddTag from "./Admin/pages/tag/AddTag";
import EditTag from "./Admin/pages/tag/EditTag";
import AllTag from "./Admin/pages/tag/AllTag";

import MenuBuilder from "./Admin/pages/builder/MenuBuilder";
import FooterMenuBuilder from "./Admin/pages/builder/FooterMenuBuilder";

import HomeLayout from "./Admin/pages/home/HomeLayout";
import AllReview from "./Admin/pages/review/AllReview";
import AllOrder from "./Admin/pages/order/AllOrder";
import PendingOrder from "./Admin/pages/order/PendingOrder";
import CompleteOrder from "./Admin/pages/order/CompleteOrder";

import AllUser from "./Admin/pages/user/AllUser";
import AllUserDoctor from "./Admin/pages/user/AllUserDoctor";
import UserView from "./Admin/pages/user/UserView";
import ThemeSetting from "./Admin/pages/home/ThemeSetting";

import AllZones from "./Admin/pages/zones/AllZones";
import EditZones from "./Admin/pages/zones/EditZones";
import AddZones from "./Admin/pages/zones/AddZones";

import AllTax from "./Admin/pages/tax/AllTax";
import EditTax from "./Admin/pages/tax/EditTax";
import AddTax from "./Admin/pages/tax/AddTax";

import AllPromo from "./Admin/pages/promo/AllPromo";
import EditPromo from "./Admin/pages/promo/EditPromo";
import AddPromo from "./Admin/pages/promo/AddPromo";
import OrderView from "./Admin/pages/order/OrderView";

import AddPage from "./Admin/pages/pages/AddPage";
import AllPage from "./Admin/pages/pages/AllPage";
import EditPage from "./Admin/pages/pages/EditPage";
import AllImages from "./Admin/pages/Gallery/AllImages";
import AllEnquire from "./Admin/pages/enquire/AllEnquire";

import Forgot from "./Admin/pages/Forgot";
import ProfileDoctorPending from "./Admin/pages/user/ProfileDoctorPending";
import ProfileHospitalPending from "./Admin/pages/user/ProfileHospitalPending";
import ProfileVendorPending from "./Admin/pages/user/ProfileVendorPending";

import ProfileEdit from "./Admin/pages/user/ProfileEdit";

import Admin404 from "./Admin/pages/Admin404";
import AdminLoader from "./Admin/pages/AdminLoader";
import getCookie from "./helpers/getCookie";
import getDecryptData from "./helpers/getDecryptData";

import { Toaster } from "react-hot-toast";

import getUserData from "./helpers/userAuth";
import AllUserVendor from "./Admin/pages/user/AllUserVendor";
import AllUserHospital from "./Admin/pages/user/AllUserHospital";

import JobView from "./Admin/pages/job/JobView";

import AllVendorEnquire from "./Admin/pages/enquire/AllVendorEnquire";

import { ThemeProvider } from "./fetchdata/ThemeContext";
import AllDoctorEnquire from "./Admin/pages/enquire/AllDoctorEnquire";
import AllHospitalEnquire from "./Admin/pages/enquire/AllHospitalEnquire";
import ProfileVendorView from "./Admin/pages/user/ProfileVendorView";
import ProfileHospitalView from "./Admin/pages/user/ProfileHospitalView";
import ProfileDoctorView from "./Admin/pages/user/ProfileDoctorView";
import AllPlansCard from "./Admin/pages/plans/AllPlansCard";
import AddJob from "./Admin/pages/job/AddJob";

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

function App() {
  const location = useLocation(); // Get the current location from React Router
  const isLoginFromLocalStorage = getCookie("token") ? true : false;
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    isLoginFromLocalStorage
  );

  const [isUsertype, setisUsertype] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsUserAuthenticated(isLoginFromLocalStorage);
      const mydata = await getDecryptData();
      if (mydata) {
        const mydatatype = mydata.type;
        setisUsertype(mydatatype);
      }

      console.log("mydata", mydata, isLoginFromLocalStorage);

      window.scrollTo(0, 0);
      const timeoutId = setTimeout(() => {
        // const rootElement = document.getElementById("root");
        // if (rootElement.innerHTML === "") {
        //   navigate("/error", { replace: true });
        //   window.location.reload();
        //   setIsError(true);
        //   console.log("error found");
        // }
        // console.log(" found", rootElement);
      }, 1000); // Adjust the timeout duration as needed

      return () => clearTimeout(timeoutId);
    };

    fetchData();
  }, [isLoginFromLocalStorage, location.pathname]);

  useEffect(() => {
    // Dynamically create a <link> element to load the CSS file
    const linkElement = document.createElement("link");
    linkElement.rel = "stylesheet";
    linkElement.href = "../assets/css/volt.css"; // Adjust the path as needed
    document.head.appendChild(linkElement);
    getUserData();
  }, []);

  return (
    <>
      <ThemeProvider>
        <Toaster />
        <Routes>
          <Route
            path="/"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && (
                    <Navigate to="/profile/vendor/pending" />
                  )) ||
                  (isUsertype === 2 && (
                    <Navigate to="/profile/doctor/pending" />
                  )) ||
                  (isUsertype === 3 && (
                    <Navigate to="/profile/hospital/pending" />
                  )) ||
                  (isUsertype === 0 && <Navigate to="/dashboard" />))) || (
                <Login />
              )
            }
          />
          <Route path="/forgot" element={<Forgot />} />

          <Route
            path="/dashboard"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 1 && isUsertype !== 0 && <AdminLoader />) ||
                  (isUsertype === 1 && <Navigate to="/vendor/dashboard" />) ||
                  (isUsertype === 0 && <Dashboard />))) || <Navigate to="/" />
            }
          />
          <Route
            path="/profile/doctor/pending"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 1 && isUsertype !== 3 && isUsertype !== 2 && (
                  <AdminLoader />
                )) ||
                  (isUsertype === 1 && <Navigate to="/vendor/dashboard" />) ||
                  (isUsertype === 2 && <ProfileDoctorPending />))) || (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/profile/vendor/pending"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 3 &&
                  isUsertype !== 2 && <AdminLoader />) ||
                  (isUsertype === 1 && <ProfileVendorPending />) ||
                  (isUsertype === 2 && (
                    <Navigate to="/vendor/dashboard" />
                  )))) || <Navigate to="/" />
            }
          />

          <Route
            path="/profile/hospital/pending"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && <Navigate to="/vendor/dashboard" />) ||
                  (isUsertype === 2 && (
                    <Navigate to="/profile/doctor/pending" />
                  )) ||
                  (isUsertype === 3 && <ProfileHospitalPending />) ||
                  (isUsertype === 0 && <Navigate to="/dashboard" />))) || (
                <Login />
              )
            }
          />
          <Route
            path="/all-vendor-enquire"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && <AllVendorEnquire />) ||
                  (isUsertype === 2 && <AdminLoader />) ||
                  (isUsertype === 3 && <AdminLoader />) ||
                  (isUsertype === 0 && <AdminLoader />))) || <Login />
            }
          />

          <Route
            path="/all-doctor-enquire"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && <AdminLoader />) ||
                  (isUsertype === 2 && <AllDoctorEnquire />) ||
                  (isUsertype === 3 && <AdminLoader />) ||
                  (isUsertype === 0 && <AdminLoader />))) || <Login />
            }
          />

          <Route
            path="/all-hospital-enquire"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && <AdminLoader />) ||
                  (isUsertype === 2 && <AdminLoader />) ||
                  (isUsertype === 3 && <AllHospitalEnquire />) ||
                  (isUsertype === 0 && <AdminLoader />))) || <Login />
            }
          />

          <Route
            path="/profile/edit"
            element={
              (isUserAuthenticated &&
                ((isUsertype !== 0 &&
                  isUsertype !== 1 &&
                  isUsertype !== 2 &&
                  isUsertype !== 3 && <AdminLoader />) ||
                  (isUsertype === 1 && <ProfileEdit />) ||
                  (isUsertype === 2 && <ProfileEdit />) ||
                  (isUsertype === 3 && <ProfileEdit />) ||
                  (isUsertype === 0 && <ProfileEdit />))) || <Login />
            }
          />

          <Route path="/addblog" element={<Addblog />} />
          <Route path="/add-gallery" element={<AddGallery />} />
          <Route path="/buildBlogs/:slug" element={<BuildBlogs />} />
          <Route path="/allblogs" element={<AdminAllBlogs />} />
          <Route path="/edit-blog/:slug" element={<EditAdminBlogs />} />

          <Route path="/add-category/" element={<AddCategory />} />
          <Route path="/all-category/" element={<AllCategory />} />
          <Route path="/edit-category/:slug" element={<EditCategory />} />

          <Route path="/add-product/" element={<AddProduct />} />
          <Route path="/all-product/" element={<AllProduct />} />
          <Route path="/edit-product/:slug" element={<EditProduct />} />

          <Route path="/add-attribute/" element={<AddAttribute />} />
          <Route path="/all-attribute/" element={<AllAttribute />} />
          <Route path="/edit-attribute/:slug" element={<EditAttribute />} />

          <Route path="/add-specialization/" element={<AddSpecialization />} />
          <Route path="/all-specialization/" element={<AllSpecialization />} />
          <Route
            path="/edit-specialization/:slug"
            element={<EditSpecialization />}
          />

          <Route path="/add-interested/" element={<AddInterested />} />
          <Route path="/all-interested/" element={<AllInterested />} />
          <Route path="/edit-interested/:slug" element={<EditInterested />} />

          <Route path="/add-business/" element={<AddBusiness />} />
          <Route path="/all-business/" element={<AllBusiness />} />
          <Route path="/edit-business/:slug" element={<EditBusiness />} />

          <Route path="/add-department/" element={<AddDepartment />} />
          <Route path="/all-department/" element={<AllDepartment />} />
          <Route path="/edit-department/:slug" element={<EditDepartment />} />

          <Route path="/add-interested/" element={<AddDepartment />} />
          <Route path="/all-department/" element={<AllDepartment />} />
          <Route path="/edit-department/:slug" element={<EditDepartment />} />

          <Route path="/add-tag/" element={<AddTag />} />
          <Route path="/all-tag/" element={<AllTag />} />
          <Route path="/edit-tag/:slug" element={<EditTag />} />
          <Route path="/add-menu" element={<MenuBuilder />} />
          <Route path="/add-footer-menu" element={<FooterMenuBuilder />} />

          <Route path="/home-layout" element={<HomeLayout />} />
          <Route path="/reviews" element={<AllReview />} />
          <Route path="/all-enquire" element={<AllEnquire />} />

          <Route path="/all-order" element={<AllOrder />} />
          <Route path="/pending-order" element={<PendingOrder />} />
          <Route path="/complete-order" element={<CompleteOrder />} />

          <Route path="/order/:userId/:orderId" element={<OrderView />} />

          <Route path="/all-user" element={<AllUser />} />
          <Route path="/all-doctor" element={<AllUserDoctor />} />
          <Route path="/all-vendor" element={<AllUserVendor />} />
          <Route path="/all-hospital" element={<AllUserHospital />} />

          <Route path="/user/:slug" element={<UserView />} />
          <Route path="/user-vendor/:slug" element={<ProfileVendorView />} />
          <Route
            path="/user-hospital/:slug"
            element={<ProfileHospitalView />}
          />
          <Route path="/user-doctor/:slug" element={<ProfileDoctorView />} />

          <Route path="/theme-setting" element={<ThemeSetting />} />

          <Route path="/add-zones/" element={<AddZones />} />
          <Route path="/all-zones/" element={<AllZones />} />
          <Route path="/edit-zones/:slug" element={<EditZones />} />

          <Route path="/add-taxes/" element={<AddTax />} />
          <Route path="/all-taxes/" element={<AllTax />} />
          <Route path="/edit-taxes/:slug" element={<EditTax />} />

          <Route path="/add-promo/" element={<AddPromo />} />
          <Route path="/all-promo/" element={<AllPromo />} />
          <Route path="/edit-promo/:slug" element={<EditPromo />} />

          <Route path="/add-page/" element={<AddPage />} />
          <Route path="/all-page/" element={<AllPage />} />
          <Route path="/edit-page/:slug" element={<EditPage />} />
          <Route path="/all-images" element={<AllImages />} />

          <Route path="/job-view/" element={<JobView />} />

          <Route path="/all-plans/" element={<AllPlansCard />} />
          <Route path="/add-job/" element={<AddJob />} />

          <Route path="*" element={<Admin404 />} />
        </Routes>
        <ScrollToTop />
      </ThemeProvider>
    </>
  );
}

export default App;

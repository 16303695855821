// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import axiosInstance from "../axiosInstance";
import getDecryptData from "../helpers/getDecryptData";
import getCookie from "../helpers/getCookie";
import eraseCookie from "../helpers/eraseCookie";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [getAuth, setAuth] = useState([]);

  const dataAuth = async () => {
    const decryptdatajson = await getDecryptData();
    const id = decryptdatajson?._id;

    const credentials = {
      id: id,
    };
    const authuser = getCookie("token");
    if (!authuser) {
      return;
    }

    try {
      const { data } = await axiosInstance.post("/auth-user", credentials);

      const { success, existingUser } = data;
      if (success) {
        console.log(existingUser.type);
        return existingUser;
      } else if (data.success === false) {
        toast.error(data.message);
        eraseCookie("token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dataAuth();
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        getAuth,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import Swal from "sweetalert2"; // Import SweetAlert2
import withReactContent from "sweetalert2-react-content"; // Import React components for SweetAlert2

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import LoadingTable from "../../components/LoadingTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const MySwal = withReactContent(Swal); // Create a SweetAlert2 instance with React components

function AllPlansCard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/admin/all-page?page=${currentPage}&limit=${limit}&search=${searchTerm}`
      );
      setData(response.data.Mpage);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, limit]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle delete
  const handleDelete = (categoryId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      customClass: {
        popup: "custom-swal-popup", // Add a class to the entire dialog
        confirmButton: "btn-danger", // Add a class to the confirm button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`/admin/delete-page/${categoryId}`)
          .then(() => {
            // Refresh the data or update the state after successful deletion
            fetchData();
            toast.success("Deleting page success!");
          })
          .catch((error) => {
            console.error("Error Taxes data:", error);
            toast.error("Error Taxes data");
          });
      }
    });
  };

  const inputRef = React.createRef();

  const handleSearchInputChange = (e) => {
    setCurrentPage(1);
    setTotalPages(1);
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    fetchData(currentPage);
  };

  const copyUrl = (id) => {
    const url = `/page/${id}`;
    // Copying URL to clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard:", url);
        toast.success("Category URL copied successfully!");
      })
      .catch((error) => {
        toast.error("Category URL copied Failed!");
        console.error("Failed to copy URL to clipboard:", error);
        // Handle errors here
      });
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <Navbar />
        <div className="py-4">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4"> All Plans</h1>
            </div>
            <div className="d-none">
              <Link
                to="/add-page"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                  stroke-linejoin="arcs"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Add Page
              </Link>
            </div>
          </div>
        </div>

        <div className="card bg-transparent border-0 mb-4">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-4">
              {/* Card */}
              <div className="card mb-4 mb-xl-0">
                <div className="card-header border-gray-100 py-5 px-4">
                  {/* Price */}
                  <div className="d-flex mb-3">
                    <span className="h5 mb-0">Rs</span>{" "}
                    <span
                      className="price display-2 mb-0"
                      data-annual={0}
                      data-monthly={0}
                    >
                      0
                    </span>{" "}
                    <span className="h6 fw-normal align-self-end">/month</span>
                  </div>
                  <h4 className="mb-3 text-black">Free trial</h4>
                  <p className="fw-normal mb-0">
                    If you're new just need the basics.
                  </p>
                </div>
                <div className="card-body py-5 px-4">
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Enquiry<span className="fw-bolder ms-2">1</span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Doctor View <span className="fw-bolder ms-2">3</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Hospital View <span className="fw-bolder ms-2">3</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Job Post <span className="fw-bolder ms-2">3</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Product Upload{" "}
                      <span className="fw-bolder ms-2">Unlimited</span>{" "}
                    </span>
                  </div>
                </div>
                <div className="card-footer border-gray-100 d-grid px-4 pb-4">
                  {/* Button */}{" "}
                  <a
                    href="./sign-up.html"
                    target="_blank"
                    className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center"
                  >
                    Buy Now
                    <svg
                      className="icon icon-xs ms-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl-4">
              {/* Card */}
              <div className="card mb-4 mb-xl-0">
                <div className="card-header border-gray-100 py-5 px-4">
                  {/* Price */}
                  <div className="d-flex mb-3">
                    <span className="h5 mb-0">Rs</span>{" "}
                    <span
                      className="price display-2 mb-0"
                      data-annual={0}
                      data-monthly={0}
                    >
                      800
                    </span>{" "}
                    <span className="h6 fw-normal align-self-end">/month</span>
                  </div>
                  <h4 className="mb-3 text-black">Standard</h4>
                  <p className="fw-normal mb-0">
                    If you're new just need the basics.
                  </p>
                </div>
                <div className="card-body py-5 px-4">
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Enquiry<span className="fw-bolder ms-2">100</span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Doctor View <span className="fw-bolder ms-2">3</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Hospital View <span className="fw-bolder ms-2">50</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Job Post <span className="fw-bolder ms-2">30</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Product Upload{" "}
                      <span className="fw-bolder ms-2">Unlimited</span>{" "}
                    </span>
                  </div>
                </div>
                <div className="card-footer border-gray-100 d-grid px-4 pb-4">
                  {/* Button */}{" "}
                  <a
                    href="./sign-up.html"
                    target="_blank"
                    className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center"
                  >
                    Buy Now
                    <svg
                      className="icon icon-xs ms-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl-4">
              {/* Card */}
              <div className="card mb-4 mb-xl-0">
                <div className="card-header border-gray-100 py-5 px-4">
                  {/* Price */}
                  <div className="d-flex mb-3">
                    <span className="h5 mb-0">Rs</span>{" "}
                    <span
                      className="price display-2 mb-0"
                      data-annual={0}
                      data-monthly={0}
                    >
                      2000
                    </span>{" "}
                    <span className="h6 fw-normal align-self-end">/month</span>
                  </div>
                  <h4 className="mb-3 text-black">Premium</h4>
                  <p className="fw-normal mb-0">
                    If you're new just need the basics.
                  </p>
                </div>
                <div className="card-body py-5 px-4">
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Enquiry<span className="fw-bolder ms-2">500</span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Doctor View <span className="fw-bolder ms-2">200</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Hospital View <span className="fw-bolder ms-2">300</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Job Post <span className="fw-bolder ms-2">3</span>{" "}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <svg
                      className="icon icon-sm me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <span>
                      Product Upload{" "}
                      <span className="fw-bolder ms-2">Unlimited</span>{" "}
                    </span>
                  </div>
                </div>
                <div className="card-footer border-gray-100 d-grid px-4 pb-4">
                  {/* Button */}{" "}
                  <a
                    href="./sign-up.html"
                    target="_blank"
                    className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center"
                  >
                    Buy Now
                    <svg
                      className="icon icon-xs ms-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AllPlansCard;

import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import toast from "react-hot-toast";
import Gallery from "../../components/Gallery";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const EditCategory = () => {
  // add specification

  const [SpecificationsData, setSpecificationsData] = useState({
    specifications: [],
  });

  const addSpecification = () => {
    const newSpecification = {
      heading: "",
      labels: [{ label: "", value: "" }],
    };

    // Ensure that SpecificationsData.specifications is not undefined
    if (!SpecificationsData.specifications) {
      setSpecificationsData({
        ...SpecificationsData,
        specifications: [newSpecification],
      });
    } else {
      const updatedSpecifications = [
        ...SpecificationsData.specifications,
        newSpecification,
      ];
      setSpecificationsData({
        ...SpecificationsData,
        specifications: updatedSpecifications,
      });
    }
  };

  const removeSpecification = (index) => {
    const filteredSpecifications = SpecificationsData.specifications.filter(
      (_, i) => i !== index
    );
    setSpecificationsData({
      ...SpecificationsData,
      specifications: filteredSpecifications,
    });
  };

  const addLabelValue = (specIndex) => {
    const updatedSpecifications = [...SpecificationsData.specifications];
    updatedSpecifications[specIndex].labels.push({ label: "", value: "" });
    setSpecificationsData({
      ...SpecificationsData,
      specifications: updatedSpecifications,
    });
  };

  const removeLabelValue = (specIndex, labelIndex) => {
    const updatedSpecifications = [...SpecificationsData.specifications];
    updatedSpecifications[specIndex].labels.splice(labelIndex, 1);
    setSpecificationsData({
      ...SpecificationsData,
      specifications: updatedSpecifications,
    });
  };

  const handleInputChange = (specIndex, labelIndex, field, value) => {
    const updatedSpecifications = [...SpecificationsData.specifications];
    if (labelIndex !== null) {
      updatedSpecifications[specIndex].labels[labelIndex][field] = value;
    } else {
      updatedSpecifications[specIndex][field] = value;
    }
    setSpecificationsData({
      ...SpecificationsData,
      specifications: updatedSpecifications,
    });
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

  const [Category, setCategory] = useState({});
  const [editorValue, setEditorValue] = useState("");

  const { slug } = useParams();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    slide_head: "",
    slide_para: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    parent: "",
    status: "",
    image: "",
    filter: 1,
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/all-category");
      console.log(response.data.categories);
      setData(response.data.categories);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getCategory = async () => {
    try {
      const { data } = await axiosInstance.get(`/admin/get-category/${slug}`);
      setCategory(data.category);
      console.log(data);
      setLoading(false); // Set loading to false when data is fetched
      // Set the formData.username based on the blog's slug
      setFormData((prevData) => ({
        ...prevData,
        title: data.Category.title,
        slug: data.Category.slug,
        description: data.Category.description,
        metaTitle: data.Category.metaTitle,
        metaDescription: data.Category.metaDescription,
        metaKeywords: data.Category.metaKeywords,
        parent: data.Category.parent,
        status: data.Category.status,
        image: data.Category.image,
        slide_para: data.Category.slide_para,
        slide_head: data.Category.slide_head,
        specifications: data.Category.specifications,
        filter: data.Category.filter,
      }));

      if (data.Category.specifications !== undefined) {
        setSpecificationsData(data.Category.specifications);
      }

      setEditorValue(data.Category.description);
      // setTimeout(setnow, 2000);
      setTimeout(fetchcurrent, 1000);
      // fetchcurrent();
    } catch (error) {
      console.log(error);
      toast.error("Error fetching Single company!");
      setLoading(false); // Set loading to false when data is fetched
    }
  };

  const [showCode, setShowCode] = useState(true);
  const handleDescriptionChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };

  const toggleShowCode = () => {
    setShowCode(!showCode);
  };

  function fetchcurrent() {
    const imagePreviewElements = document.querySelectorAll(".getimg");
    if (imagePreviewElements) {
      const getimage = document.getElementById("imageid");
      imagePreviewElements.forEach((imageElement) => {
        imageElement.addEventListener("click", () => {
          setTimeout(function () {
            setFormData((prevData) => ({
              ...prevData,
              image: getimage.src,
            }));
          }, 5);
        });
      });
    }
  }

  useEffect(() => {
    getCategory();
    fetchData();
  }, []);

  const submitData = async () => {
    setSubmitLoading(false);
    const getimage = document.getElementById("imageid");

    const updatedFormData = {
      ...formData,
      image: getimage.src,
      specifications: SpecificationsData,
    };

    // console.log(formData)
    try {
      await axiosInstance.put(
        `/admin/update-category/${slug}`,
        updatedFormData
      );
      toast.success("Category Updated successfully!");
      navigate("/all-category");
    } catch (error) {
      console.error("Error On Blog:", error);
      console.log(formData);
      toast.error(error.response.data.message);
    } finally {
      setSubmitLoading(true);
    }
  };

  return (
    <>
      <Sidebar />
      <main class="content">
        <Navbar />

        <div className="py-4">
          <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <a href="#">
                  <svg
                    className="icon icon-xxs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Category
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <div className="mb-3 mb-lg-0">
              <h1 className="h4">Edit Category</h1>
            </div>
            <div>
              {" "}
              <Link
                to="/all-category"
                id="goBackButton"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  style={{ transform: "rotate(180deg)" }}
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Go back
              </Link>
            </div>
          </div>
        </div>
        <div id="add-blog-form">
          <div className="row">
            <div className="col-md-8 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  {Category ? (
                    <>
                      <div className="row mb-4">
                        <div className="col-lg-12 col-sm-12">
                          <div className="row">
                            <br />
                            <div className="col-md-12">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 70,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <p
                                  className="skeleton"
                                  style={{
                                    width: "100%",
                                    borderRadius: 5,
                                    margin: "0px auto 10px",
                                    height: 135,
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row mb-4">
                        <div className="col-lg-12 col-sm-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label htmlFor="username">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  value={formData.title}
                                  name="title"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-4">
                                <label htmlFor="title">Parent category</label>

                                <select
                                  className="form-control select"
                                  name="parent"
                                  value={formData.parent}
                                  onChange={handleChange}
                                  id="parent"
                                >
                                  <option value={""}> None</option>

                                  {loading ? (
                                    <option>Loading...</option>
                                  ) : (
                                    data.map((category) => (
                                      <option
                                        key={category.id}
                                        value={category._id}
                                      >
                                        {category.title}
                                      </option>
                                    ))
                                  )}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="slug">slug </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="slug"
                                  value={formData.slug}
                                  name="slug"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="metaTitle">
                                  Banner Heading
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="slide_head"
                                  value={formData.slide_head}
                                  name="slide_head"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="metaDescription">
                                  Banner Description
                                </label>
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  name="slide_para"
                                  id="slide_para"
                                  value={formData.slide_para}
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  htmlFor="description"
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  {" "}
                                  Description
                                  <span></span>
                                  <button
                                    onClick={toggleShowCode}
                                    className="btn btn-secondary btn-sm"
                                    type="button"
                                  >
                                    {showCode ? "Show Editor" : "Show Code"}
                                  </button>
                                </label>

                                {showCode ? (
                                  <textarea
                                    className="form-control bg-primary text-white"
                                    value={formData.description}
                                    onChange={(e) =>
                                      handleDescriptionChange(e.target.value)
                                    }
                                    rows={5}
                                    cols={80}
                                    name="description"
                                    style={{ lineHeight: "2em" }}
                                  />
                                ) : (
                                  <ReactQuill
                                    value={formData.description}
                                    onChange={(value) =>
                                      handleDescriptionChange(value)
                                    }
                                    modules={{
                                      toolbar: [
                                        [{ header: "1" }, { header: "2" }],
                                        [{ size: [] }],
                                        ["bold", "italic", "underline"],
                                        [
                                          { list: "ordered" },
                                          { list: "bullet" },
                                        ],
                                        ["link"],
                                        ["clean"],
                                      ],
                                    }}
                                    formats={[
                                      "header",
                                      "size",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "list",
                                      "bullet",
                                      "link",
                                    ]}
                                    bounds={".app"}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  htmlFor="KeyFeatures"
                                  className="d-block mb-2"
                                >
                                  FAQ Section
                                </label>
                                {loading ? (
                                  <>
                                    <div
                                      className="card-1 skeleton"
                                      style={{ height: 213, borderRadius: 20 }}
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    {SpecificationsData &&
                                      SpecificationsData.specifications &&
                                      SpecificationsData.specifications.length >
                                        0 &&
                                      SpecificationsData.specifications.map(
                                        (specification, specIndex) => (
                                          <div
                                            key={specIndex}
                                            className="col-md-12 mb-2"
                                            style={{
                                              background: "#f2f4f6",
                                              padding: 10,
                                              borderRadius: 10,
                                            }}
                                          >
                                            {/* <h6>Specification {specIndex + 1}</h6> */}
                                            <div className="mb-2">
                                              <label>Heading:</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={specification.heading}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    specIndex,
                                                    null,
                                                    "heading",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            {specification.labels.map(
                                              (label, labelIndex) => (
                                                <div
                                                  key={labelIndex}
                                                  className="row mb-2"
                                                >
                                                  <div className="col-md-5">
                                                    <label>Label:</label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={label.label}
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          specIndex,
                                                          labelIndex,
                                                          "label",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-5">
                                                    <label>Value:</label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={label.value}
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          specIndex,
                                                          labelIndex,
                                                          "value",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-md-2">
                                                    <label className="d-block">
                                                      Action
                                                    </label>
                                                    {labelIndex === 0 && (
                                                      <button
                                                        className="btn btn-primary mt-0"
                                                        onClick={() =>
                                                          addLabelValue(
                                                            specIndex
                                                          )
                                                        }
                                                        type="button"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width={20}
                                                          height={20}
                                                          viewBox="0 0 24 24"
                                                          fill="none"
                                                          stroke="white"
                                                          strokeWidth={2}
                                                          strokeLinecap="square"
                                                          strokeLinejoin="arcs"
                                                        >
                                                          <line
                                                            x1={12}
                                                            y1={5}
                                                            x2={12}
                                                            y2={19}
                                                          />
                                                          <line
                                                            x1={5}
                                                            y1={12}
                                                            x2={19}
                                                            y2={12}
                                                          />
                                                        </svg>
                                                      </button>
                                                    )}
                                                    {labelIndex !== 0 && (
                                                      <button
                                                        className="btn btn-danger mt-0"
                                                        onClick={() =>
                                                          removeLabelValue(
                                                            specIndex,
                                                            labelIndex
                                                          )
                                                        }
                                                        type="button"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          class="dropdown-icon text-white"
                                                          fill="white"
                                                          viewBox="0 0 20 20"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            fill-rule="evenodd"
                                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                            clip-rule="evenodd"
                                                          ></path>
                                                        </svg>
                                                      </button>
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            <button
                                              className="btn btn-danger"
                                              onClick={() =>
                                                removeSpecification(specIndex)
                                              }
                                              type="button"
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        )
                                      )}
                                  </>
                                )}

                                <button
                                  onClick={addSpecification}
                                  className="btn btn-secondary btn-sm mb-2"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#1f2937"
                                    stroke-width="2"
                                    stroke-linecap="square"
                                    stroke-linejoin="arcs"
                                  >
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                  </svg>
                                  Add FAQ
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="metaTitle">Meta Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="metaTitle"
                                  value={formData.metaTitle}
                                  name="metaTitle"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="metaDescription">
                                  Meta Description
                                </label>
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  name="metaDescription"
                                  id="metaDescription"
                                  value={formData.metaDescription}
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-4">
                                <label htmlFor="metaKeywords">
                                  Meta Keywords
                                </label>
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  value={formData.metaKeywords}
                                  name="metaKeywords"
                                  id="metaKeywords"
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card border-0 shadow components-section">
                <div className="card-body">
                  {Category ? (
                    <>
                      <div className="row">
                        <h5
                          className="skeleton"
                          style={{
                            width: "100%",
                            borderRadius: 5,
                            margin: "0px auto 10px",
                            height: 50,
                          }}
                        >
                          {" "}
                        </h5>

                        <hr />

                        <p
                          className="skeleton mb-3"
                          style={{
                            width: "100%",
                            borderRadius: 5,
                            margin: "0px auto 10px",
                            height: 250,
                          }}
                        >
                          {" "}
                        </p>

                        <div className="col-6 ">
                          <p
                            className="skeleton mb-3"
                            style={{
                              width: "100%",
                              borderRadius: 5,
                              margin: "0px auto 10px",
                              height: 40,
                            }}
                          >
                            {" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-6">
                        <p
                          className="skeleton mb-3"
                          style={{
                            width: "100%",
                            borderRadius: 5,
                            margin: "0px auto 10px",
                            height: 40,
                          }}
                        >
                          {" "}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <h5> Category Image (1376 × 275 px) </h5>

                        <hr />

                        <div className="input-group imageupload-bx mb-3">
                          <img
                            src={formData.image}
                            className=""
                            id="imageid"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-default"
                          />
                          <input
                            type="hidden"
                            className="form-control imageopen"
                            id="imageinput"
                            name="image"
                            defaultValue={formData.image}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-4 ">
                          <div className="mb-4">
                            <label className="my-1 me-2" htmlFor="status">
                              Status
                            </label>
                          </div>
                        </div>
                        <div className="col-8 ">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="status"
                              value="true"
                              onChange={handleChange}
                              checked={formData.status === "true" && "checked"}
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="status"
                            >
                              Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input text-danger"
                              type="radio"
                              name="status"
                              id="status1"
                              value="false"
                              onChange={handleChange}
                              checked={formData.status === "false" && "checked"}
                            />
                            <label
                              className="form-check-label  text-danger"
                              htmlFor="status1"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>

                        <hr />

                        <div className="col-4 ">
                          <div className="mb-4">
                            <label className="my-1 me-2" htmlFor="status">
                              Filter
                            </label>
                          </div>
                        </div>
                        <div className="col-8 ">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="filter"
                              id="filter1"
                              value="1"
                              onChange={handleChange}
                              checked={
                                (formData.filter === "1" ||
                                  formData.filter === 1) &&
                                "checked"
                              }
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="filter1"
                            >
                              Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input text-danger"
                              type="radio"
                              name="filter"
                              id="filter2"
                              value="0"
                              onChange={handleChange}
                              checked={
                                (formData.filter === "0" ||
                                  formData.filter === 0) &&
                                "checked"
                              }
                            />
                            <label
                              className="form-check-label  text-danger"
                              htmlFor="filter2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <Gallery />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-12">
                    {SubmitLoading ? (
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={submitData}
                      >
                        Update Category
                      </button>
                    ) : (
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        disabled
                      >
                        <span class="ms-1">Loading...</span>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditCategory;
